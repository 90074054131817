.checkbox_field {
    width: 1.2rem;
    margin-right: 1rem;
    user-select: none;
    /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1); */
}

.checkbox_field:checked {
    width: 1.2rem;
    /* height: 30px; */
    border-radius: 3px;
    margin-right: 1rem;
    user-select: none;
    /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1); */
}

.admin_form_container {
    width: 100%;
    display: flex;
}

.selectPackage {
    width: 100%;
    display: block;
}

.package {
    display: flex;
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    padding: 0.5rem;
    /* background-color: red; */
    margin: 0.5rem;
    min-height: 10vh;
    align-items: center;
    justify-content: center;
}

.package_not_selected {
    display: flex;
    text-align: center;
    border: 2px solid rgb(203, 203, 203);
    border-radius: 10px;
    padding: 0.5rem;
    /* background-color: red; */
    margin: 0.5rem;
    min-height: 10vh;
    align-items: center;
    justify-content: center;
}

.textfield_form {
    width: 100%;
}

.create_package {
    text-align: center;
    width: 100%;
    padding-bottom: 1rem;
    display: inline-block;
}

.packages_container {
    display: flex;
}

.packages {
    width: 100%;
}

.edit_packages {
    width: 100%;
    display: flex;
    border-radius: 10px;
    padding: 0rem 0.3rem;
    /* background-color: red; */
    margin: 0.5rem;
    border: 2px solid black;
    display: inline-block;
}

.package_row {
    width: 100%;
    display: flex;
    margin: 1.5rem 0rem;
}


/* SERVICES--------------- */

.service_container {
    display: flex;
}

.service {
    display: flex;
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    padding: 0.5rem;
    background-color: rgb(237, 235, 235);
    margin: 0.5rem;
    min-height: 4vh;
    align-items: center;
    justify-content: center;
}

.hide {
    display: none;
}

.service_not_selected {
    display: flex;
    text-align: center;
    border: 2px solid rgb(188, 188, 188);
    border-radius: 10px;
    padding: 0.5rem;
    /* background-color: red; */
    margin: 0.5rem;
    min-height: 4vh;
    align-items: center;
    justify-content: center;
}

.services_container {
    display: flex;
}

.services {
    /* height: 80vh; */
    max-height: 70vh;
    overflow-y: scroll;
    width: 30%;
}

.edit_service {
    width: 100%;
    /* padding: 1.5rem; */
    /* background-color: red; */
    margin: 0.5rem;
    display: inline-block;
}

.service_info_container {
    padding: 1rem;
    /* background-color: rgb(235, 233, 233); */
    border-radius: 10px;
    border: 2px solid black;
}

.service_row {
    width: 100%;
    display: flex;
    /* padding-left: 0.5rem; */
    /* margin-right: 2.5rem; */
    justify-content: space-between;
    margin: 1.5rem 0rem;
}


/* ---------------- */

.textfield {
    /* padding: 0rem 0.5rem; */
}

@media screen and (max-width: 768px) {
    .packages_container {
        display: block;
    }
}