/* src/fonts/fonts.css */

@font-face {
    font-family: 'CustomFont';
    src: url('./Roboto-Black.ttf') format('truetype');
  }
  
  .custom-font {
    font-family: 'CustomFont', Arial, sans-serif;
  }
  