.product-table-container {
    width: 100%;
    /* max-width: 800px; */
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.hover-overlay-red,
.hover-overlay-blue {
  pointer-events: none; /* Ensure hover does not trigger on overlays */
}

.hero_img:hover{
    scale: 1.5;
    z-index: 99;
}

.hover-overlay-red:hover {
  opacity: 0.5;
}

.hover-overlay-blue:hover {
  opacity: 0.5;
}

.role_div {
    display:flex;
    flex-direction:column;
    gap:2px;
    min-width: 5rem;
}

.current_role {
    background: rgba(0, 128, 0, 0.3);
    padding: 0.2rem 0.5rem;
    color:rgb(0, 128, 0);
}

.dire_current_role{
    background: rgba(255, 0, 0, 0.3);
    padding: 0.2rem 0.5rem;
}

.current_role:hover{
    cursor: pointer;
    background: rgba(0, 128, 0, 1);
    color:white;
}


.dire_current_role:hover {
    cursor: pointer;
    background: red;
    color:white;

}

.remove-icon {
    opacity: 0;
    pointer-events: none;
  }
  
  div:hover .remove-icon {
    opacity: 1;
    pointer-events: all;
  }

.radient_hero_img {
    max-width:1.5rem;
    border-radius:5px;
    text-align:center;
    opacity:0.5;
    color:red;
}

.radient_hero_img:hover{
cursor: pointer;
background:red;
opacity: 1;
color:black;
}
.product-table-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.product-table-body {
    width: 100%;
}

.product-table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.product-table-row:hover {
    color: #000000 !important;
}

.product-table-row:hover .product-title {
    color: #000000 !important;
}

.product-table-row:hover .product-description {
    color: #000000 !important;
}
.product-table-cell {
    display: flex;
    flex-direction: row;

    margin-right: 1rem;
}

.product-table-cell img {
    max-width: 100px;
    height: auto;
    margin-right: 15px;
}

.product-table-cell_button {
    display: flex;
    flex-direction: row;
    min-width: fit-content;
}

.product-details {
    word-wrap: break-word; overflow-wrap: break-word;
    flex: 1;
}

.product_details_milddle {
    word-wrap: break-word; overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    /* width: 33.33%; */
    text-align: start;
}

.product_details_milddle:hover{
    color:#000000;
    cursor: pointer;
}

.product_details_milddle > span:hover {
    color:#000000;

}



.product-title {
    font-weight: bold;
    color: #000;
    word-wrap: break-word; overflow-wrap: break-word;
}

.product-description {
    color: #777; word-wrap: break-word; overflow-wrap: break-word;
}

.btn {
    margin-left: 1rem;
    display: inline-block;
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
}

.btn:hover {
    background-color: #777;
    color: eab308;
}
