.app {
    /* text-align: center; */
    margin: 0 auto;
    width: 50%;
}

.selected-date {
    margin: 0 auto;
}

.times button {
    display: block;
    background-color: #6f48eb;
    color: white;
    margin-top: 5px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: white; */
}

.App-link {
    color: #61dafb;
}


/* react calendar */

.react-calendar {
    /* width: 700px; */
    /* max-width: 100%; */
    /* background-color: rgb(198, 198, 198); */
    color: black;
    /* border-radius: 8px; */
    /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation__label__labelText {
    color: #1d73ed;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
}

.react-calendar__navigation button {
    color: #00B0B2;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    /* background: none; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
    color: #00B0B2;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    margin-bottom: 1rem;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
    /* color: #d10000; */
    /* color: #00B0B2; */
}

.react-calendar__month-view__days__day {
    /* color: #00B0B2; */
    border: 0.5px solid #ededed !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    /* color: #00B0B2; */
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 1em 1em;
    background: none;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #1d73ed;
    color: white;
}

.react-calendar__tile--now {
    background: rgb(249, 243, 176);
    color: black;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #1d73ed;
    color: white;
}

.react-calendar__tile--hasActive {
    background: #1d73ed;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #1d73ed;
}

.react-calendar__tile--active {
    background: #1d73ed;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1d73ed;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}