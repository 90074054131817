/* App.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #2C3639;
}

.App {
    text-align: center;
}

#welcome {
    background-image: url('https://lh3.googleusercontent.com/fife/APg5EOZjx9ueyKN-dYq569jw2hg3Zx3md9L3xHecFZV6QowStERXjI63Yw0WlS9Uqv8t5Q_OKdfslOgjg-BR894-tSYsmT7rEeT4PLNJ2RLjhiyVw3WnQKxmRLYZnBHcwS4mgzcP_16MymoZIo7vVtSIaA67Zmuu3fK5hspVQ6UsOs-fs-dJCVldr8w08-55osJzAm68fORa8JqTW3PDdp-gU1oGM5OhoaXpTpS7GmE9OzKhKmS7JPyFstydd6F7SnqfzDk28syJzblUQuDTDCZ6coF4-jVDg9XB_AkcoLfOFJRFJRiVURxrr3EPC_L4L5TGbpy6XoffAi60a102CE-tWVCizX9BTa0QhX17heuZCsSOz2w44mmNS1L--DZ9HpTvf0aVt8MDbnoiBazAtuR0NfjZtWpi8VAcPTE5zdo8NFwU2qu2fXHeBquR2yzZEIWXEOu7EaNSO56OeDxo2Q4lYinaVXEie1EuyTSZpiTr7lz_qc86uN7McSf_c_ubp3OFV1kbNocdLNKBECeczEcpfY5Dii29Lmt17XLGe04WEJpxVZ2a4R_0N6WK0sB68gJD10je-fzmfu-5PzLJZoKIaBpOfYbvuDaE83TweLUD_cKOy_plroNYoUz-m7dGGIX2uBzpMCw54MqiFVpmVRjseN7gyDgw-nlUXkF5JFWpXn3z1ofU2nWPHS3I0CeXExHdqVR9szYiOCxpg3eIn-KZe144icwwngTSGpiA5TtmTp2O4oyx1C7aA6UUTJLcIsaPhEvLUSCPFshAl_umhaBkePhNGtvqLN2Q7iJuijr8-9xA0bEdpiGbTwPbk0EbF0qQ37VByZ-0nMCM8eWf3T8OWs87UY0Cul-K3xSRtyozBq9vbHkVwvGPICQfVc9dc1X9pgI0l_56Rq54-doehSyEbC65NPYy6JOI8bE9ikeep9tJDWDChoGXoTXRSkAVkCHgI3MiP9cvwKP8niqWzQRVZ93_NTPOAWf8bCDchpcqMtRMJStHKqY7z99_3OlM3ece9ShNFbqtToq45UxLyRVedmgk3EupS-YXbqAZbMzSMZPbe4yp8AZx7tbpdEvsJmd7P5jHEIGzRjpPL2yhVPWFyq6WMiP1pD2WmMOskAaYPQBbIX9rXU668EWfCmIGzjWA-AkPrFZBTQaQNB-ljTRaNJtK8wpU7s-QMilNRWv0V26BJSPfSpKp1HsX4F3uizdr1uFr_ubrNlm2LS7-Hb0AQSGW-uuZC6D6EwGsnqHPtpfPnJkBml9lBxqqj6dVy0I14_ieMgGOoaQAV8q09YBs2s2lNhNvhrfslw9yRjZ0nqv9ERbSsBeKU3xerUzCYOEPB16xfvIn3AjSDkA2iaNHbQEkBKVxKTPnRPZx8XApdkXoLKLUBc91NNGtbkPSVurkrn4ySrh-MHPOeT5BmyrIWySKZ7tYDzNN6hAG1ZjuWw6KRRgEl6uXRrvt10OFx-Ki8wZv504lEmheRuw0tVdEgNKSBPxP_rSYMyiT0lgKsL5usCts6NfpcIXziueBTQsxLY0GSw=w1242-h977');
    background-size: cover;
    background-position: center;
}

.keywords-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto the next row */
    gap: 12px 8px;

    padding: 10px;
    align-items: center; /* Align items properly */
    justify-content: flex-start; /* Align items to the left */
  }
  
  .keyword {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1; /* Ensures text height is minimal */

  }
  
  .highlighted {
    background-color: #daf8dc; /* Green when found */
    color: #267b3a;
  }
  
  .faded {
    background-color: #faeced; /* Red when not found */
    color: #cb4e5a;
  }

.hover {
    cursor: pointer !important;
}

.extra_info {
    display: block;
    padding: 1rem;
    text-align: start;
}

#equipment {
    max-width: 82%;
    margin: auto;
    margin-bottom: 4rem;
}

#equipment img {
    max-height: 30vh;
}

#about_us {
    background-color: #000103;
}

#about_us_content,
#prices_container,
#services,
#news_container,
#presentation,
#footer_container,
#current_news {
    padding-top: 7rem;
    margin: auto;
    max-width: 82%;
}

.about-us-prod-header {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    text-align: start;
    font-size: 33px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #fff;
}

.about-us-prod-info {
    text-align: start;
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 300;
    font-size: x-large;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #fff;
}

.mobile_show {
    display: none;
}

.about-us-prod-header-services {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    text-align: start;
    font-size: 33px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #000103;
}

.about-us-prod-info-services {
    text-align: start;
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 300;
    font-size: x-large;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #000103;
}

.about_us_intro {
    padding: 20px 0px;
    display: flex;
    width: 100%;
}

.about_us_header {
    padding: 20px;
    width: 100%;
}

.about_us_bottom {
    display: flex;
    width: 100%;
    padding-bottom: 1rem;
}

.about_us_top {
    display: flex;
    width: 100%;
}

.about_us_top_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin-top: 10vh; */
    padding: 20px;
}

.about_us_buttom_right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10vh;
    padding: 20px;
}

.brand_name {
    /* font-size: 60px; */
    font-family: Avenir Next LT Pro;
    font-size: 7rem;
    font-weight: 700;
    line-height: 8rem;
    letter-spacing: 0.05em;
    text-align: left;
}

.welcome-content {
    position: absolute;
    display: flex;
    left: 50%;
    top: 50%;
    width: 82%;
    transform: translate(-50%, -50%);
    text-align: left;
    justify-content: left;
    color: #ffffff;
    /* Adjust the text color as needed */
}

.right_content_button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 7rem;
    font-family: Avenir LT Std;
    font-size: 35px;
    font-weight: 350;
    line-height: 50px;
    letter-spacing: 0.03em;
    text-align: left;
}

.welcome-content p {
    text-align: start;
}

.welcome-brand-name {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 48px;
    /* or 137% */
    letter-spacing: 0.05em;
}

.mobile_menu {
    display: none;
}

.welcome-brand-identity {
    margin-bottom: 2rem;
}

.welcome-brand-identity {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 350;
    font-size: 2rem;
    line-height: 2.8rem;
    /* or 150% */
    letter-spacing: 0.03em;
}

.headerCss {
    display: flex;
    margin: 0rem 7rem;
}

.mobile-hide {
    display: block;
}

.home_div {
    user-select: none;
    width: 100%;
}

.mobile-show {
    display: none;
}

.header {
    /* position: sticky; */
    user-select: none;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: #000103;
    padding: 25px 0px;
    /* margin-bottom: 1rem; */
    align-items: center;
    justify-content: space-between;
}

.booking_header {
    /* position: sticky; */
    user-select: none;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: #FAF9F6;
    padding: 25px 0px;
    /* margin-bottom: 1rem; */
    align-items: center;
    justify-content: space-between;
}

.main_news {
    width: 70%
}

.main_news_container {
    padding: 2rem 4rem 5rem;
    text-align: left;
}

.logo-text:hover {
    cursor: pointer;
}

.logo {
    padding-left: 2rem;
    flex-grow: 1;
    text-align: start;
}

.logo-text {
    color: #FAF9F6;
    font-size: 24px;
    margin: 0;
}

.navigation {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.menu-icon {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 18px;
    cursor: pointer;
}

.bar {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
}

.bar-colored {
    height: 2px;
    background-color: #FAF9F6;
    transition: transform 0.3s ease-in-out;
    width: 100%;
}

.spacer-mid {
    width: 35%;
}

.spacer-bottom {
    width: 70%;
}

.bar.open:nth-child(2) {
    opacity: 0;
}

.segment-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.segment-link {
    color: #FFF;
    font-size: 15px;
    margin: 0 10px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    text-transform: uppercase;
}

.segment-link-booking {
    color: #000103;
    font-size: 15px;
    margin: 0 10px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    text-transform: uppercase;
}

.segment-link.active,
.segment-link-booking.active {
    font-weight: bold;
    /* background-color: #FFF; */
    border: 8px;
    color: #00B0B2;
    /* color: #FFF; */
}

.segment-link:hover {
    color: #00B0B2;
}

.mobile-list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.mobile-link {
    border-top: 1px solid #FFF;
    padding: 1rem;
    list-style-type: none;
    color: #FFF;
    /* font-size: 15px; */
    /* margin: 0 10px; */
    /* width: 100% !important; */
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    text-transform: uppercase;
}

.mobile-link.active {
    font-weight: bold;
    /* background-color: #FFF; */
    border: 8px;
    color: #00B0B2;
    /* color: #FFF; */
}

.mobile-link:hover {
    color: #00B0B2;
}

.scroll-top {
    position: 'fixed';
    bottom: '20px';
    right: '20px';
    z-Index: '9999';
}

.about-prod-header {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    text-align: start;
    font-size: 33px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #000103;
}

.about-prod-header-book {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 33px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #000103;
}

.about-prod-info {
    text-align: start;
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 350;
    font-size: x-large;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #000103;
}

.about-prod-info-book {
    text-align: center;
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 350;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000103;
}

.presentation_container {
    display: flex;
}

.segment {
    /* background-color: #A27B5C; */
    color: #FFF;
    /* margin-bottom: 20px; */
    width: 100%;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000;
}

.logout {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.3rem 0.5rem;
    color: #1d73ed;
    border: 2px solid #1d73ed;
    border-radius: 10px;
}

.pending {
    background-color: yellow;
    color: black;
    border: none !important;
  }

  .inProgress {
    background-color: blue;
    color: white;    border: none !important;
  }

  .written {
    background-color: orange;
    color: black;    border: none !important;
  }

  .editor {
    background-color: purple;
    color: white;    border: none !important;
  }

  .completed {
    background-color: green;
    color: white;    border: none !important;
  }

.status {
    padding: 0.3rem 1.2rem;
    border: 1px solid lightgray;
    border-radius: 30px;
    margin: 0rem 0.5rem;
    /* margin:"1rem 1rem"; */
}

.pending-board {
    background-color: lightgray;
    color: black;
    border: none !important;
  }

  .inprogress-board {
    background-color: blue;
    color: white;    border: none !important;
  }

  .written-board {
    background-color: lightgreen;
    color: black;    border: none !important;
  }

  .editor-board {
    background-color: orange;
    color: white;    border: none !important;
  }

  .completed-board {
    background-color: darkgreen;
    color: white;    border: none !important;
  }

  .revision-board{
    background-color: purple !important;
    color: white;    border: none !important;
  }
  .failed-board {
    background-color: red;
    color: white;    border: none !important;
  }
.status {
    padding: 0.3rem 1.2rem;
    border: 1px solilef_sided lightgray;
    border-radius: 30px;
    margin: 0rem 0.5rem;
    /* margin:"1rem 1rem"; */
}


.start:last-child {
    margin-right: 0rem;
}

.urgent {
    background: #ffdfe3
}

Button {
    transition: background-color 0.3s ease;
}
Button:hover {
    opacity: 0.5 !important; /* Fallback for browsers that don't support mix() */
    /* background-color: mix(black, 10%)  ; */
    /* border: 2px solid black !important; */
}

.logout:hover {
    background-color: #1d73ed;
    color: white;
    cursor: pointer;
}

.segment.h-100 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-container {
    display: flex;
    padding: 1rem;
    /* max-width: 100%; */
}

.presentation_calendar {
    width: 40%;
}

.presentation_separator {
    display: block;
    width: 10%;
}

.presentation_contact {
    width: 50%;
}

.image-container {
    display: flex;
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.service_button {
    position: "absolute";
    bottom: "0px";
    left: "0px";
    /* width: "auto"; */
    max-width: 400px !important;
    overflow-wrap: "break-word";
    background: red;
    /* height: "3.5rem"; */
    /* // fontSize: "1rem"; */
    color: "white";
    border: "0";
    font-weight: 700;
    box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
}

.button_container:hover {
    cursor: pointer;
    background-color: black !important;
}

.shadow {
    box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
}

.services_button :hover {
    /* borderColor: 'transparent' , color: "black", background: colors[index], */
}

.mb5 {
    margin-bottom: 5rem;
}

.margin-top {
    margin-top: 2rem;
}

.current_news_img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.current_news {
    text-align: start;
    margin: 0rem 1rem;
    max-height: 60vh;
    /* padding: 2rem; */
    /* background-color: rgb(223, 223, 223); */
    box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
    border-radius: 10px;
}

.current_news:hover {
    cursor: pointer;
}

.current_news img {
    max-height: 50% !important;
    min-height: 50% !important;
    object-fit: cover;
}

.current_news_container {
    min-height: 40%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.cnc_category {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 750;
    font-size: 10px;
    line-height: 24px;
    /* identical to box height, or 240% */
    letter-spacing: 0.03em;
    color: #00B0B2;
}

.cnc_description {
    margin-top: 0px;
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.03em;
    color: #000103;
}

.cnc_title {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 200% */
    letter-spacing: 0.03em;
    color: #000103;
}

.custom-button {
    padding: 10px 20px;
    color: black;
    border-color: white;
    border-width: 2px;
}

.button-container_edit {
    position: absolute;
    bottom: 0px;
    left: 30px;
    width: auto;
    max-width: 450px !important;
    overflow-wrap: break-word;
    align-items: center;
    background: #00B0B2;
    display: flex;
    /* justify-content: center; */
    /* height: 3.5rem; */
    /* font-size: 1rem; */
    color: black;
    border: 0;
    padding: 20px;
    /* max-height: 2vh; */
    font-weight: 700;
}

.image {
    object-fit: cover;
    flex: 0 0 auto;
    width: 80%;
    max-width: 80%;
    height: auto;
}

.image:first-child {
    width: 35%;
    max-width: 35%;
}

.image:last-child {
    width: 60%;
    max-width: 60%;
}

#news {
    margin-bottom: 5rem;
    display: 'grid';
    grid-template-columns: 'repeat(auto-fit, minmax(400px, 1fr))';
    gap: 20px;
}

.second_desc {
    padding: 20px
}

#news_content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-bottom: 5rem;
}

.news_content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-bottom: 5rem;
}

.admin_news_content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-bottom: 5rem;
}

.mouse_hover:hover {
    cursor: pointer;
}

#all_services {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    margin-bottom: 5rem;
}

#footer {
    background: #000103;
    min-height: 50vh;
}

#footer_container {
    display: none;
    padding: 1rem;
    padding-top: 3rem;
    /* width: 100%; */
    display: flex;
    /* min-height: 300px; */
    padding-bottom: 4rem;
    background: #000103;
    color: white;
}

#footer_info {
    width: 100%;
}

.footer_map {
    width: 100%;
    min-height: 40vh;
}

.brand_name_short {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 16px;
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent background */
    animation: fadeIn 0.5s;
}

.hidden {
    display: none;
}

.container_loading {
    position: relative;
    width: 100%;
    /* Adjust as per requirement */
    height: 400px;
    /* Adjust as per requirement */
    /* border: 1px solid #1d73ed; */
    /* For visibility */
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: #1d73ed;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: loading 2s infinite;
}

    .active_category {
        background:black;
        color:white;
    }

    .active_subcategory {
    text-decoration:underline;
    font-weight:bold;
    }

@keyframes loading {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.5);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.contact-modal {
    width: 40%;
    max-width: 85%;
    min-width: 85%;
    /* Adjust max-width as per your design */
    padding: 10px;
    border-radius: 20px;
    height: 80vh;
    background-color: #181818;
    text-align: center;
    /* Transparent pink */
    /* border: 3px solid rgb(237, 120, 237); */
    /* Neon pink border */
    border-radius: 30px;
    /* box-shadow: 0 0 10px #ed77ed, 0 0 20px #ed77ed, 0 0 40px #ed77ed; */
    /* Neon glow */
    /* Keep the rest of your styles */
}

.user_name:hover {
    background-color: #1d73ed;
    color: white;
    cursor: pointer;
}

.settings:hover {
    cursor: pointer;
    color: #1d73ed;
}

.brand_name_long {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 350;
    font-size: x-large;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.suggest_password:hover {
    color: blue;
    cursor: pointer;
    user-select: none;
}

#last_book {
    background-color: #1d73ed;
    /* text-align: start; */
    padding-bottom: 3rem;
}

.guideline_button {
    color: "#1d73ed";
    margin: '0rem 1rem 1rem 1rem';
    background: white;
}

.guideline_button:hover {
    background-color: #1d73ed !important;
    color: white !important;
}

#company_guidelines .ql-toolbar.ql-snow {
    display: none;
    border-bottom: 1px solid;
}

#company_guidelines .ql-editor {
    border: 1px solid lightgray;
}

.brand_phone,
.brand_address {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    color: white;
}

.text-color-white {
    color: white;
}

.third-row {
    object-fit: cover;
    flex: 0 0 auto;
    width: 80%;
    max-width: 80%;
    height: auto;
}

.third-row:first-child {
    width: 60%;
    max-width: 60%;
}

.third-row:last-child {
    width: 35%;
    max-width: 35%;
}

.segment.h-100-top {
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.segment.h-100 h2 {
    font-size: 48px;
}

.segment h2 {
    font-size: 24px;
    margin-top: 0;
}

.hide {
    display: none;
}

.language-dropdown {
    margin-left: 1rem;
}

.padding20 {
    padding: 20px 0px;
}

.current_package {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    margin-bottom: 5rem;
}

.button_container {
    left: 30px;
}

.main_container {
    min-height: 100vh;
    display: flex;
    overflow-wrap: break-word;
}

.other_news {
    width: 30%;
    margin-top: 5rem;
}

.other_news_header {
    display: none;
}

.news_title {
    font-size: large;
    font-weight: bold;
}

.mobile_show {
    display: none !important;
}


/* Responsive Styles */

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .button_container {
        left: 5px;
    }

    .news_title {
        font-size: auto;
        font-weight: bold;
    }
    .brand_name {
        padding-right: 2rem;
        font-size: 80px;
    }
    .headerCss {
        margin: 0rem 4rem;
    }
    .main_news_container {
        padding: 2rem 1rem 5rem;
        text-align: left;
        overflow-wrap: break-word;
    }
    .news_add_container {
        font-size: 15px;
    }
}

.right_side {
    background: #f3f3f3;
    min-height: 100%;
    width: 85%;
}

.mobile {
    display: none ;
}

.company_view {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.header_logout{
    min-height: 10vh;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.mobile_img {
    display: none;
}
.hide_horizontal {
    display: block !important;
}

.board_hide {

    display: flex;
    flex-direction: row;
}

.company_headers {
    padding: 0rem 2rem;
}

.table_rows {
    background: white;
    min-height: 20vh;
    padding: 0rem 2rem;
}

.actions_header {
    margin-left: 1.2rem;
    padding: 0.2rem 0.5rem;
    border-radius: 50px;
    font-weight: bold;
    font-size: 15px;
    min-width: 5%;
    display: flex;
    justify-content: center;
}

.topic_header {
    padding: 0.2rem 0.5rem;
    border-radius: 50px;
    font-weight: bold;
    font-size: 15px;
    min-width: 20%;
}

.url_header {
    padding: 0.2rem 0.5rem;
  /* border: 1px solid gray; */
  border-radius: 50px;
  font-size: 15px;
  max-width: 3%;
  font-weight: bold;
  min-width: 3%;
}

.status_header{
    padding: 0.2rem 0.5rem;
  /* border: 1px solid gray; */
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;
  max-width: 8%;
  min-width: 8%;
  display: flex; /* Added to apply justifyContent */
  justify-content: center;
}

.checkbox_css {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    padding: 10px;
    border-radius: 10px;

}
.admin_row_css_checkbox {
    padding: 0.2rem 0.5rem;
    border-radius: 50px;
    font-weight: bold;
    font-size: 15px;
    min-width: 5%;
    display: flex;
    justify-content: center;
}
.admin_row_css_keywords {
    padding: 0.2rem 0.5rem;
    border-radius: 50px;
    font-weight: bold;
    font-size: 15px;
    min-width: 20%;
    justify-content: center;
}
.admin_row_css_status {
    font-size: 15px;
    font-weight: bold;
    max-width: 8%;
    min-width: 8%;
}
.admin_row_css_url {
    padding: 0.2rem 0.5rem;
    /* border: 1px solid gray; */
    border-radius: 50px;
    font-size: 15px;
    max-width: 5%;
    font-weight: bold;
    overflow: hidden;
    min-width: 3%;
}

.settings_input {
    width: 30%;
}

.blog_container {
    width: 60%;
    max-width: 60%;
    min-width: 60%;
    background: white;
    border-radius: 20px;
    padding: 2rem;
    max-height: 70vh; /* Adjusted to 70% of the viewport height */
    overflow-y: auto; /* Allows vertical scrollbar when content overflows */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    scrollbar-color: blue white; /* Sets scrollbar color to blue and scrollbar track to white */
    scrollbar-width: thin; /* Makes scrollbar thinner */
}

.modal_main_container {
    min-height: 55vh;
  max-height: 55vh;
  overflow: scroll;
}
.guideline_css {
    width: 35%;
  padding: 0.5rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: lightgray;
  border-radius: 30px;
}
.login_button {
    background: #1d73ed;
    /* padding: 1rem 5rem; */
}

.login_button:hover {
    cursor: pointer;
    background: #00B0B2 !important;
}

.loading_login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure this fits within your button */
  }

  .dot {
    width: 8px; /* Adjust size to fit inside the button */
    height: 8px;
    border-radius: 50%;
    background-color: white; /* Or any color that matches your button's theme */
    margin: 0 2px; /* Space between dots */
    animation: bounce 1.2s infinite ease-in-out;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px); /* Smaller movement for subtle effect */
    }
  }

  .dot:nth-child(1) {
    animation-delay: 0s;
  }

  .dot:nth-child(2) {
    animation-delay: 0.4s; /* Slightly adjust the delay for a smooth sequential effect */
  }

  .dot:nth-child(3) {
    animation-delay: 0.8s;
  }

  .create_acc {
    display: flex;
  flex-direction: row;
  justify-content: end;
  color: #1d73ed;
  user-select: none;
  }
  .create_acc:hover {
    cursor: pointer;
    color: black;
  }

.blog_buttons {
 display: flex;
  justify-content: end;
}

.user_settings {
 width: 30%;
  background: white;
  border-radius: 20px;
}

.plus_css {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.user_deadline {
 padding: 0.2rem 0.5rem;
  /* border: 1px solid gray; */
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;
  max-width: 8%;
  min-width: 8%;
}

.user_url {
  padding: 0.2rem 0.5rem;
  /* border: 1px solid gray; */
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;
  max-width: 3%;
  min-width: 3%;
}

.user_blog_length {
 padding: 0.2rem 0.5rem;
  /* border: 1px solid gray; */
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;
  max-width: 20%;
  min-width: 20%;
}

.user_keywords_2 {
  padding: 0.2rem 0.5rem;
  border-radius: 50px;
  font-weight: bold;
  font-size: 15px;
  min-width: 20%;
}

.user_keywords {
  padding: 0.2rem 0.5rem;
  border-radius: 50px;
  font-weight: bold;
  font-size: 15px;
  min-width: 20%;
}

.assign_blogs {
  width: 30%;
  background: white !important;
  border-radius: 20px;
}

.admin_employee {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.blog_css {
  min-height: 15vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.bottom_view {
   display: flex;
  justify-content: start;
  align-items: center;
  gap:15px;
  flex-direction:column;
}

.ql-toolbar.ql-snow {
    position: sticky  !important;
    top: 0  !important;
    background: white  !important;
    z-index: 100 !important;
    border-bottom: 1px solid #ccc  !important;
  }

.top_blog {

  align-items: center;
  gap:20px;
  flex-direction:row;
  display: flex;
  width: 100%;
  justify-content: start;

}




@media screen and (max-width: 768px) {


.top_blog {
  flex-direction:column !important;
    flex-direction: column;
    align-items: start;
}

.bottom_view {
  display: flex;
  flex-direction:column;
  justify-content: start;
  align-items: center;
  gap:15px;
}

.admin_employee {
padding:0.5rem
}
.blog_css {

padding:1rem;

}
    .topic_header {
        min-width: 45%;
        max-width: 45%;
    }
    .status {
        min-width: 100px;
        text-align:center;
    }
    .assign_blogs {
    width:95%;
      background: white !important;
        z-index:999 !important;
    }

    .user_keywords_2 {
padding:0rem;
  max-width: 35%;
  min-width: 35%;
}

    .user_keywords {
padding:0rem;
  max-width: 45%;
  min-width: 45%;
}


        .user_blog_length {
padding:0rem;
  max-width: 20%;
  min-width: 20%;
}

    .user_url {
padding:0rem;
  max-width: 10%;
  min-width: 10%;
}
    .guideline_css {
        min-width: 80%;
    }
.user_deadline {
padding:0rem;
  max-width: 20%;
  min-width: 20%;
}
    .plus_css {
    padding:0.5rem;
    }
    .user_settings {
        width:80%;
    }
    .blog_buttons {

  padding: 1rem;
 }
    .mobile_create_guideline {
        min-width: 100% !important;
    }

    .blog_container {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 0.5rem;
        max-height: 85vh;
    }
    .modal_main_container {
        min-height: 70vh;
        max-height: 70vh;
    }
    .settings_input {
        width:100%;
    }
    .admin_row_css_status {
        min-width: 20%;
        max-width: 20%;
        padding: 0rem 0.5rem 0rem 0rem;
    }
    .admin_row_css_checkbox {
        padding: 0rem 0rem 0rem 0.5rem;
    }
    .admin_row_css_keywords {
        padding: 0rem;
        max-width:45%;
        min-width: 45%;
    }
    .checkbox_css {
        margin-left: 0px;
    }
    .admin_timeslot{
      min-height: 3rem;
    }
    .admin_row_css_url {
        min-width: 10%;
        max-width: 10%;
        padding: 0rem;
    }
    .url_header{
        min-width: 10%;
        max-width: 10%;
        padding: 0rem;
    }
    .status_header  {
        min-width: 20%;
        max-width: 20%;
    }
    .login-form {
        min-width: 65%;
    }
    .actions_header {
        margin-left: 0rem;
    }
    .other_news_header {
        display: block;
    }
    .hide_horizontal, .board_hide{
        display: none !important;
    }
    .company_headers {
        padding: 0rem;
    }
    .table_rows {
        padding: 0rem;
    }

    .active_spans {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }


    .regular_user {
        max-width: 100%;
        overflow-x: scroll;
        padding: 1rem 0rem 0rem 0rem;
        /* background-color:#00B0B2 ; */
        border-radius: 0;
    }
    .header_logout {
    border-bottom: 2px solid #1d73ed;
    justify-content: space-between;
    }
    .mobile_img {
        display: block !important   ;
    }
    .mobile {
        display: flex;
    }
    .mobile_companies {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        max-width: 100%;
    }

    .company_view{
        padding: 2rem 1rem 0rem 1rem;
    }
 .right_side{
    width: 100%;
 }
    .left_side {
        display: none !important;
    }
    .other_news {
        width: 100%;
        margin-top: 0rem;
    }
    .welcome-content {
        top: 60%;
        width: 80%;
    }
    .main_container {
        display: block;
        overflow-wrap: break-word;
    }
    .main_news {
        width: 100%;
    }
    .main_news_container {
        padding: 0rem 0rem 3rem;
    }
    .button_container {
        left: 5px;
    }
    .welcome-brand-name {
        margin-bottom: 1rem;
        font-family: 'Avenir Next LT Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: 0.05em;
    }
    .about_us_top {
        display: block;
        width: 100%;
    }
    .about_us_top_left {
        /* Remove the properties for the responsive style */
        width: initial;
        display: initial;
        margin-top: initial;
        padding: initial;
    }
    .flex-container {
        padding: 0rem;
    }
    .mobile_hide {
        display: block;
    }
    .current_news {
        text-align: start;
        margin: 0rem 0rem;
        max-height: 100%;
        /* padding: 2rem; */
        /* background-color: rgb(223, 223, 223); */
        box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
        border-radius: 10px;
        margin-bottom: 3rem;
    }
    .opened {
        max-width: 60% !important;
    }
    .about_us_header {
        width: auto;
    }
    .booking_header {
        background-color: #000103;
    }
    #equipment {
        max-width: 100%;
        /* margin: auto; */
    }
    #about_us_content,
    #prices_container,
    #services,
    #news_container,
    #footer_container {
        padding-top: 5rem;
        /* max-width: 100%; */
    }
    #footer_container {
        display: none;
    }
    #about_us_content {}
    #equipment img {
        max-height: 100%;
    }
    .padding20 {
        padding: 20px;
    }
    .welcome-brand-identity {
        margin-top: 2rem;
        font-family: 'Avenir LT Std';
        font-style: normal;
        font-weight: 350;
        font-size: 1.1rem;
        line-height: 1.5rem;
        letter-spacing: 0.03em;
    }
    .segment.h-100 h2 {
        font-size: 32px;
    }
    .mobile-hide {
        display: none;
    }
    .mobile-show {
        display: block;
    }
    .second_desc {
        padding: 40px 0px;
    }
    .headerCss {
        margin: 0rem;
    }
    .menu-icon {
        display: flex;
    }
    .footer_map {
        min-height: auto;
    }
    .about-us-prod-info {
        padding: 0px 0px 40px 0px;
    }
    .segment-list {
        display: none;
    }
    .mobile_menu {
        /* display: flex; */
        /* background-color: red; */
    }
    .mobile_menu_show {
        display: block;
    }
    .mobile_menu_hide {
        display: none;
    }
    .about-prod-header-book {
        text-align: start;
    }
    .about-prod-info-book {
        text-align: start;
    }
    .language-dropdown {
        display: none;
    }
    .navigation.open ul.segment-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #3F4E4F;
        padding: 10px 0;
        z-index: 1;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
    }
    #last_book {
        text-align: start;
    }
    .navigation.open ul.segment-list li {
        margin: 10px 0;
        display: flex;
        justify-content: center;
    }
    .navigation.open ul.segment-list li.segment-link {
        color: #FFF;
    }
    .navigation.open ul.segment-list li.segment-link.active {
        color: #A27B5C;
    }
    .navigation.open ul.segment-list li.segment-link:hover {
        color: #A27B5C;
    }
    .navigation.open {
        position: relative;
    }
    .navigation.open .bar:nth-child(1) {
        transform: translateY(8px) rotate(-45deg);
    }
    .navigation.open .bar:nth-child(2) {
        transform: scaleX(0);
    }
    .navigation.open .bar:nth-child(3) {
        transform: translateY(-8px) rotate(45deg);
    }
    .navigation.open ul.segment-list {
        opacity: 1;
        visibility: visible;
    }
    #news {
        margin-bottom: 5rem;
        display: 'grid';
        gap: 20px;
    }
    #all_services {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        gap: 20px;
        margin-bottom: 5rem;
    }
    .presentation_container {
        display: block;
    }
    .presentation_calendar {
        width: 100%;
    }
    .presentation_separator {
        display: none;
    }
    .presentation_contact {
        width: 100%;
        margin-top: 1rem;
    }
    #news_content {
        display: block;
        /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
        gap: 0px;
        margin-bottom: 5rem;
    }
    .segment-list.hide {
        display: none;
    }
    .about_us_intro {
        display: block;
    }
    .about_us_bottom {
        display: none;
    }
    .current_package {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
        margin-bottom: 5rem;
    }
    .mobile_show {
        display: block !important;
    }
    .mobile_hide {
        display: none !important;
    }
}