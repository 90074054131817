.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    width: 20%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);

    padding: 2rem;  
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #f7f7f7; */
}

.login-form h2 {
    text-align: center;
}

.login-form .textfield {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 15px;
}

.login-form button {
    width: 100%;
    padding: 10px;
    margin-top: 2rem;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: black !important;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}