.product-table-container {
    width: 100%;
    /* max-width: 800px; */
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.product-table-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.product-table-body {
    width: 100%;
}

.product-table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.product-table-row:hover {
    color: #eab308 !important;
}

.product-table-row:hover .product-title {
    color: #eab308 !important;
}

.product-table-row:hover .product-description {
    color: #eab308 !important;
}
.product-table-cell {
    display: flex;
    flex-direction: row;

    margin-right: 1rem;
}

.product-table-cell img {
    max-width: 100px;
    height: auto;
    margin-right: 15px;
}

.product-table-cell_button {
    display: flex;
    flex-direction: row;
    min-width: fit-content;
}

.product-details {
    word-wrap: break-word; overflow-wrap: break-word;
    flex: 1;
}

.product_details_milddle {
    word-wrap: break-word; overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    /* width: 33.33%; */
    text-align: start;
}

.product_details_milddle:hover{
    color:#eab308;
    cursor: pointer;
}

.product_details_milddle > span:hover {
    color:#eab308;

}



.product-title {
    font-weight: bold;
    color: #000;
    word-wrap: break-word; overflow-wrap: break-word;
}

.product-description {
    color: #777; word-wrap: break-word; overflow-wrap: break-word;
}

.btn {
    margin-left: 1rem;
    display: inline-block;
    padding: 10px 20px;
    background-color: #eab308;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
}

.btn:hover {
    background-color: #777;
    color: eab308;
}
